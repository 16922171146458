const invoke_ = require('lodash/invoke')
const { pageCodeRun } = require('./logger/biEventCreators')

const reportRunCodeBi = ({
  appLogger,
  platformBi,
  userId,
  appDefinitionId,
  pageName
}) => {
  const {
    networkPageLoadStart,
    isServerSide,
    getBsi,
    metaSiteId,
    viewerSessionId,
    pageId,
    pageUrl,
    viewMode
  } = platformBi

  if (isServerSide) {
    return
  }

  const tsn = networkPageLoadStart
    ? Date.now() - Math.round(networkPageLoadStart)
    : null

  const biEvent = pageCodeRun({
    metaSiteId,
    bsi: invoke_(getBsi),
    viewerSessionId,
    pageId,
    pageName,
    pageUrl,
    userId,
    appDefinitionId,
    viewMode,
    tsn
  })

  appLogger.bi(biEvent)
}

module.exports.reportRunCodeBi = reportRunCodeBi
