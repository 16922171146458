import _ from 'lodash'
import { FormsFieldPreset, FormPlugin } from '@wix/forms-common'
import { FormPreset } from '../../../../constants/form-types'
import { ROLE_FORM, STEP_ROLE, THANK_YOU_STEP_ROLE } from '../../../../constants/roles'
import successMessageStructure from '../../../../assets/presets/hidden-message.json'
import submitButtonStructure from '../../../../assets/presets/submit-button.json'
import { fieldsStore } from '../../preset/fields/fields-store'
import { formEnhancers } from './get-subscribers-form-enhancers'
import { ElementsLayout, GSExtraData } from './get-subscribers-types'
import { fieldEnhancerByFieldName } from './get-subscribers-field-enhancers'
import sideInputLabel from '../../../../assets/presets/side-input-label.json'
import {
  textSpan,
  GSLabels,
  containerStyle,
  buttonStyle,
  textParagraph,
  titleStyles,
  getTextAlignmentFromBodyAlignment,
  getTextAlignmentFromHeaderAlignment,
  containerBGColor,
} from './get-subscribers-style'
import {
  isSideLabelSkinGS,
  GSTextInputFields,
  isSkinWithStepBackground,
} from './get-subscribers-utils'
import {
  getFieldLabelLayoutLocation,
  layoutThankYouMessage,
  getElementLayout,
  mobileStructureAddition,
  getSideLabelLayout,
  EXTRA_WIDTH_SIDE_LABEL,
  getTitleLayoutLabel,
} from './get-subscribers-layout'
import { createComplexField } from '../form-service'

export const createState = (settings: GetSubscribersSettings, extraData: GSExtraData) => ({
  type: 'Container',
  skin: 'wysiwyg.common.components.statebox.viewer.skins.StateBoxStateFormSkin',
  layout: {
    x: 0,
    y: 0,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  design: {
    type: 'MediaContainerDesignData',
    background: {
      color: containerBGColor(settings, extraData).bg,
      colorOpacity: isSkinWithStepBackground(settings)
        ? parseFloat(containerBGColor(settings, extraData).aBg)
        : 0,
      type: 'BackgroundMedia',
    },
  },
  // "mobileHintsQuery": "mobileHints-k0l4f1dr",
  componentType: 'wysiwyg.viewer.components.StateBoxFormState',
  style: {
    type: 'ComponentStyle',
    id: 'style-k0l4f1a62',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
    },
    style: {
      properties: {
        rd: '0px',
      },
      propertiesSource: {
        rd: 'value',
      },
      groups: {},
    },
    componentClassName: 'wysiwyg.viewer.components.StateBoxFormState',
    pageId: '',
    compId: '',
    styleType: 'custom',
    skin: 'wysiwyg.common.components.statebox.viewer.skins.StateBoxStateFormSkin',
  },
  role: STEP_ROLE,
  config: {
    title: extraData.translator.t('getSubscribers.mainStep'),
  },
})

export const createMultiStepBaseStructure = (
  getSubscribersForm: ComponentStructure,
  settings: GetSubscribersSettings,
  extraData: GSExtraData,
): RawComponentStructure => ({
  type: 'Container',
  skin: 'wysiwyg.common.components.statebox.viewer.skins.StateBoxSkin',
  componentType: 'wysiwyg.viewer.components.StateBox',
  layout: {
    x: getSubscribersForm.layout.x,
    y: getSubscribersForm.layout.y,
    width: getSubscribersForm.layout.width,
  },
  style: {
    type: 'ComponentStyle',
    id: 'style-k0l4f17s',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
    },
    style: containerStyle(settings, extraData),
    componentClassName: 'wysiwyg.viewer.components.StateBox',
    pageId: '',
    compId: '',
    styleType: 'custom',
    skin: 'wysiwyg.common.components.statebox.viewer.skins.StateBoxSkin',
  },
  role: ROLE_FORM,
  config: {
    preset: FormPreset.MULTI_STEP_SALES,
    labels: ['contacts-contacted_me', 'f32e889b-07cb-44bf-9d22-e81fc64d4b54'],
    errorMessage: extraData.translator.t('settings.errorMessage.default'),
    secondsToResetForm: 3,
    formName: extraData.translator.t('preset.getSubscribersTitleText'),
    plugins: [
      {
        id: FormPlugin.GET_SUBSCRIBERS,
      },
      {
        id: FormPlugin.MULTI_STEP_FORM,
      },
    ],
    formLabelId: '24fe219b-9a92-478f-b237-5975df68f898', // check
  },
  props: {
    type: 'StateBoxProperties',
    metaData: {
      schemaVersion: '1.0',
    },
    transition: 'NoTransition',
    transDuration: 1,
  },
})

export const createSubmitButtonComponent = (
  settings: GetSubscribersSettings,
  extraData: GSExtraData,
): RawComponentStructure => {
  const mobileStruct = mobileStructureAddition(
    () => getElementLayout(extraData.mobileLayouts.button),
    extraData,
  )

  return _.merge({}, submitButtonStructure, {
    layout: getElementLayout(extraData.desktopLayouts.button),
    ...mobileStruct,
    data: {
      label: settings.signupForm.buttonText || 'Subscribe Now', //TO Check
    },
    style: {
      style: buttonStyle(settings.style.appStyle, extraData),
      skin: _.includes([2, 3], settings.style.appStyle)
        ? 'wysiwyg.viewer.skins.button.ShinyButtonIISkin'
        : undefined,
    },
  })
}

const createSuccessMessageComponent = (extraData: GSExtraData): RawComponentStructure => {
  const titleStyle = titleStyles(GSLabels.ThankYouTitle, extraData)
  const subtitleStyle = titleStyles(GSLabels.ThankYouSubtitle, extraData)

  const title = textSpan(extraData.desktopLayouts.successTitle.textData.label, titleStyle)
  const subtitle = textSpan(extraData.desktopLayouts.successSubtitle.textData.label, subtitleStyle)

  const mobileStruct = mobileStructureAddition(
    () => layoutThankYouMessage(extraData.mobileLayouts),
    extraData,
  )

  return _.merge({}, successMessageStructure, {
    layout: layoutThankYouMessage(extraData.desktopLayouts),
    ...mobileStruct,
    data: {
      text: `<div>
        ${textParagraph(title, 'center')}
        <p style="font-style: 1em;"> <p>
        ${textParagraph(subtitle, 'center')}
        </div>`,
    },
  })
}

const createLabel = (
  extraData: GSExtraData,
  elementName,
  styles,
  alignment,
  getLayout:  (layouts: ElementsLayout) => object
) => {
  const label = _.get(extraData.desktopLayouts, elementName)
  const labelText = label?.textData.label
  if (!labelText) {
    return null
  }
  const labelSpan = textSpan(labelText, styles)
  const mobileStruct = mobileStructureAddition(() => getLayout(extraData.mobileLayouts), extraData)
  return _.merge({}, sideInputLabel, {
    data: {
      text: `
         ${textParagraph(labelSpan, alignment)}
        `,
    },
    props: {
      minHeight: null,
    },
    layout: getLayout(extraData.desktopLayouts),
    ...mobileStruct,
  })
}

const getTitles = (settings: GetSubscribersSettings, extraData: GSExtraData) => {
  const alignment = getTextAlignmentFromHeaderAlignment(settings.style.headerAlignment)
  const { showSubtitle, showTitle } = settings.signupForm

  return _.compact([
    showTitle &&
      createLabel(
        extraData,
        'title.text',
        titleStyles(GSLabels.Title, extraData),
        alignment,
        (layouts) => getTitleLayoutLabel(layouts, 'title.text', 'title.container'),
      ),
    showTitle &&
      showSubtitle &&
      createLabel(
        extraData,
        'subTitle.text',
        titleStyles(GSLabels.Subtitle, extraData),
        alignment,
        (layouts) => getTitleLayoutLabel(layouts, 'subTitle.text', 'subTitle.container'),
      ),
  ])
}

const sideLabel = (
  fieldData: GetSubscribersFormField,
  settings: GetSubscribersSettings,
  extraData: GSExtraData,
) => {
  const alignment = getTextAlignmentFromBodyAlignment(settings.style.bodyAlignment)
  const labelStyle = titleStyles(GSLabels.FieldLabel, extraData)
  const elementName = getFieldLabelLayoutLocation(fieldData, settings)
  return createLabel(
    extraData,
    elementName,
    labelStyle,
    alignment,
    (layouts) => getSideLabelLayout(layouts, elementName),
    )
}

const createBaseField = (fieldData: GetSubscribersFormField) => {
  switch (fieldData.name) {
    case 'name':
      return fieldsStore.get(FormsFieldPreset.FIRST_NAME)
    case 'email':
      return fieldsStore.get(FormsFieldPreset.MAIN_EMAIL)
    case 'agree':
      return fieldsStore.get(FormsFieldPreset.AGREE_TERMS)
    default:
      return fieldsStore.get(FormsFieldPreset.GENERAL_TEXT)
  }
}

const createFieldData = (settings: GetSubscribersSettings): GetSubscribersFormField[] => {
  const fields = []
  if (settings.generalSettings.collectName) {
    const nameField = {
      name: 'name',
      required: settings.generalSettings.nameRequired,
    }
    fields.push(nameField)
  }

  if (settings.generalSettings.collectPhone) {
    const phoneField = {
      name: 'phone',
      required: settings.generalSettings.phoneRequired,
    }
    fields.push(phoneField)
  }

  const emailField = {
    name: 'email',
    required: true,
  }

  fields.push(emailField)

  if (settings.gdpr.requireConsent) {
    const agreeTerms = {
      name: 'agree',
      required: true,
    }
    fields.push(agreeTerms)
  }

  return fields
}

const createFieldsFromSettings = (
  getSubscribersForm: ComponentStructure,
  settings: GetSubscribersSettings,
  extraData: GSExtraData,
) => {
  const fieldsData = createFieldData(settings)
  const withSideLabels = isSideLabelSkinGS(settings)
  return _.reduce(
    fieldsData,
    (curr, field) => {
      const isTextField = _.includes(GSTextInputFields, field.name)
      const isFieldWithSideLabel = withSideLabels && isTextField
      let baseField
      if (field.name === 'phone') {
        const complexPhoneBase = createComplexField({
          sameComplexFieldsOnStage: [],
          formConfig: {},
          fieldComponent: baseField,
          extraData: {},
          fieldType: FormsFieldPreset.COMPLEX_PHONE_WIDGET,
          preset: FormPreset.GET_SUBSCRIBERS,
          commonStyles: {},
          fieldsData: [],
          plugins: [FormPlugin.GET_SUBSCRIBERS],
          controllerId: 'placeholder-id',
        })
        baseField = { ...complexPhoneBase, ...complexPhoneBase.data }
      } else {
        baseField = createBaseField(field).fieldStructure() as RawComponentStructure
      }

      const fieldEnhancers = fieldEnhancerByFieldName(field.name)

      const convertedFieldStructure = fieldEnhancers.reduce<RawComponentStructure>(
        (previousFieldStructure, enhancer) =>
          enhancer({
            getSubscribersForm,
            settings,
            fieldData: field,
            convertedField: previousFieldStructure,
            extraData,
            skippedRoles: [],
          }),
        baseField,
      )

      return isFieldWithSideLabel
        ? [...curr, sideLabel(field, settings, extraData), convertedFieldStructure]
        : [...curr, convertedFieldStructure]
    },
    [],
  )
}

export const convertGetSubscribersFormToWixForms = (
  getSubscribersForm: ComponentStructure,
  settings: GetSubscribersSettings,
  extraData: GSExtraData,
): RawComponentStructure => {
  const { layout } = settings.signupForm
  settings.signupForm.layout = layout === 2 ? 0 : layout //fix old layout

  const fields = createFieldsFromSettings(getSubscribersForm, settings, extraData)

  const components: RawComponentStructure[] = [
    ...getTitles(settings, extraData),
    ...fields,
    createSubmitButtonComponent(settings, extraData), //TODO check stylable
  ]

  const mainStep = _.merge({}, createState(settings, extraData), { components })

  const thankYouStep = _.merge({}, createState(settings, extraData), {
    components: [createSuccessMessageComponent(extraData)],
    role: THANK_YOU_STEP_ROLE,
    config: {
      title: extraData.translator.t('getSubscribers.thankYouStep'),
    },
  })

  const baseStructure = _.merge(
    {},
    createMultiStepBaseStructure(getSubscribersForm, settings, extraData),
    {
      components: [mainStep, thankYouStep],
      config: {
        msid: extraData.msid,
      },
    },
  )

  const convertedStructure = formEnhancers.reduce<RawComponentStructure>(
    (previousStructure, enhancer) =>
      enhancer(settings, previousStructure, extraData, getSubscribersForm),
    baseStructure,
  )

  return convertedStructure
}
